<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Thêm đơn vị
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Tên đơn vị" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Tên đơn vị" horizontal :value.sync="item.tenDonVi" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Mã đơn vị" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Mã đơn vị" horizontal :value.sync="item.maDonVi" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CInput label="Địa chỉ" horizontal :value.sync="item.diaChi"/>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Tỉnh/Thành phố" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseSelect label="Tỉnh/Thành phố" horizontal :options="optionsTinhThanh"
                                :value.sync="item.tinhThanh"
                                :is-valid="valid" :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CInput label="Số điện thoại" horizontal :value.sync="item.soDienThoai"/>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Email" horizontal :value.sync="item.email" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CTextarea label="Ghi chú" horizontal :value.sync="item.ghiChu"/>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow>
                    <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                    <CCol sm="9" class="form-inline">
                      <CInputCheckbox label="Hoạt động" :checked.sync="item.trangThai"/>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
            <CButton color="primary" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu lại</CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { CREATE_DONVI } from '@/store/modules/don-vi/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'

export default {
  name: 'DonViCreate',
  data () {
    return {
      optionsTrangThai: statuses,
      item: {},
      optionsTinhThanh: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('donVi', {
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('donVi', { createItem: CREATE_DONVI }),
    async saveItem () {
      await this.createItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/danh-muc/don-vi' })
      }
    },
    cancel () {
      this.$router.push({ path: '/danh-muc/don-vi' })
    }
  },
  async mounted () {
    this.isLoading = true
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.isLoading = false
  }
}
</script>
